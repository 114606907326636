<template>
  <!-- 站点页面 -->
  <v-container fluid>
    <v-card align-center>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="2">
            <v-btn v-if="$root.authority=='ROLE_USER'" color="primary" @click="showDialog=true;createStation()">{{$t('lang.station.newStationBtn')}}</v-btn>
          </v-col>
          <v-col cols="12" sm="4" offset-sm="6">
            <v-text-field v-model="searchByStation" :label="$t('lang.station.searchLabel')" append-icon="mdi-magnify"></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table :search="searchByStation" :headers="stationHeaders" :items="stationList">
          <!-- <template v-slot:item.connectedKey="props">
            <v-edit-dialog
              :return-value.sync="props.item.connectedKey"          
            >
              <v-icon>mdi-key</v-icon>
              <template v-slot:input>
                <v-text-field v-model="props.item.connectedKey" readonly></v-text-field>
              </template>
            </v-edit-dialog>
          </template> -->
          <template v-slot:item.type="{item}">
            <span>{{stationsTypes.filter(stationType => stationType.value == item.type)[0].text}}</span>
          </template>
          <!-- <template v-slot:item.status="{item}">
            <span :class="item.status=='online' ? 'green--text' : 'red--text'">{{item.status=='online' ? $t('lang.station.online'): $t('lang.station.offline')}}</span>
          </template> -->
          <template v-slot:item.isPublic="{item}">
            <span>{{item.isPublic==true ? $t('lang.station.isPublicStation') : $t('lang.station.notPublicStation')}}</span>
          </template>
          <template v-slot:item.running="{item}">
            <span>{{item.running==true ? $t('lang.station.isRunning') : $t('lang.station.notRunning')}}</span>
          </template>
          <template v-slot:item.actions="{item}">
            <!-- 编辑 -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="primary lighten-1" small class="mr-2" @click="showDialog=true;editStation(item)" :disabled="$root.authority == 'ROLE_USER' && item.userId != $root.userId">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>{{$t('lang.station.editBtn')}}</span>
            </v-tooltip>
            
            <!-- 设置差分 -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" v-if="item.type=='ROVER' && ($root.authority == 'ROLE_ADMIN' || $root.authority == 'ROLE_USER_ADMIN')" color="cyan lighten-1" small class="mr-2" @click="setFixStation(item);">
                  <v-icon small>mdi-cog</v-icon>
                </v-btn>
              </template>
              <span>{{$t('lang.home.settingBtn')}}{{$t('lang.station.difference')}}</span>
            </v-tooltip>

            <!-- 设置项目 -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="teal lighten-1" small class="mr-2" @click="setProject(item);"  v-if="item.type=='ROVER'">
                  <v-icon small color="white">mdi-view-list</v-icon>
                </v-btn>
              </template>
              <span>{{$t('lang.station.setProject')}}</span>
            </v-tooltip>

            <!-- 删除项目 -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="warning lighten-1" small class="mr-2" @click="delProject(item);"  v-if="item.type=='ROVER' && item.projectId">
                  <v-icon small color="white">mdi-view-list</v-icon>
                </v-btn>
              </template>
              <span>{{$t('lang.station.deleteProject')}}</span>
            </v-tooltip>

             <!-- 解算 -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="indigo lighten-1" small class="mr-2" @click="setResolving(item);showResolvingDialog=true"  v-if="item.type=='ROVER' && $root.authority=='ROLE_USER' && (item.baseStationId || item.projectId)">
                  <v-icon small color="white">mdi-calculator</v-icon>
                </v-btn>
              </template>
              <span>{{$t('lang.project.resolving')}}</span>
            </v-tooltip>

             <!-- 删除 -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="error lighten-1" small :disabled="$root.authority == 'ROLE_USER' && item.userId != $root.userId" @click="delStation(item.id)">
                  <v-icon small>mdi-trash-can</v-icon>
                </v-btn>
              </template>
              <span>{{$t('lang.station.deleteBtn')}}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <!-- 新建/编辑站对话框 -->
    <v-dialog v-model="showDialog" max-width="500">
      <v-card>
        <v-card-title>{{editIndex == -1 ? $t('lang.station.newStationTitle') : $t('lang.station.stationEditorTitle')}}</v-card-title>
        <v-card-text>
          <v-form ref="form">
          <v-row dense>
            <v-col cols="12">
              <v-text-field :label="$t('lang.station.stationId')" v-model="form.sid" :rules="stationIdRules" :counter="20" :readonly="editIndex != -1"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field :label="$t('lang.station.stationName')" v-model="form.stationName" :rules="stationNameRules" :counter="20"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                :items="stationsTypes"
                :label="$t('lang.station.stationType')"
                return-object
                item-value="value"
                item-text="text"
                v-model="form.selectedStationType"
                :readonly="editIndex != -1"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field :label="$t('lang.station.latitude')" v-model="form.latitude" :rules="latitudeRules" v-if="form.selectedStationType.value=='BASE'"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field :label="$t('lang.station.longitude')" v-model="form.longitude" :rules="longitudeRules" v-if="form.selectedStationType.value=='BASE'"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field :label="$t('lang.station.height')" v-model="form.height" :rules="heightRules" v-if="form.selectedStationType.value=='BASE'"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-switch v-if="form.selectedStationType.value=='BASE'" :label="$t('lang.station.setAsPublicStation')" v-model="form.isPublicStation" ></v-switch>
              <!-- <v-switch v-else :label="$t('lang.station.runningStatus')" v-model="form.running" ></v-switch> -->
            </v-col>
          </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" @click="showDialog = false">{{$t('lang.station.cancelBtn')}}</v-btn>
          <v-btn color="primary" @click="saveStation()">{{$t('lang.station.saveBtn')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 设置差分 -->
    <v-dialog v-model="showDifferenceDialog" max-width="500">
        <v-card>
          <v-card-title>{{$t('lang.station.difference')}}</v-card-title>
          <v-card-text>
            <v-form ref="differenceForm">
              <v-row dense>
                <v-col cols="12">
                  <v-select
                    :items="fixStations"
                    :label="$t('lang.station.referenceStation')"
                    return-object
                    item-value="id"
                    item-text="name"
                    v-model="differenceForm.fixStation"
                    :disabled="differenceForm.lock"
                    :rules="referenceStationRules"
                  ></v-select>
                  </v-col>
                  <v-col cols="12">
                   <v-switch :label="$t('lang.station.lock')" v-model="differenceForm.lock"></v-switch>
                  </v-col>
               </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" @click="showDifferenceDialog = false" >{{$t('lang.station.cancelBtn')}}</v-btn>
            <v-btn color="primary" @click="saveDifference()">{{$t('lang.station.saveBtn')}}</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- 给流动站设置项目对话框 -->
    <v-dialog v-model="showProjectDialog" max-width="500">
        <v-card>
          <v-card-title>{{$t('lang.station.setProject')}}</v-card-title>
          <v-card-text>
            <v-form ref="projectInfoForm">
              <v-row dense>
                <!-- <v-col cols="12" v-if="roverStationCurrentProject != ''">
                  <div class="mb-1">{{$t('lang.project.currentRoverList')}}:</div>
                    <v-chip close class="mr-1" @click:close="delRoverStation(roverStationCurrentProject)">{{roverStationCurrentProject.name}}</v-chip>
                </v-col> -->
                <v-col cols="12">
                  <v-select
                    :items="projectList"
                    :label="$t('lang.project.projectName')"
                    return-object
                    item-value="id"
                    item-text="name"
                    v-model="projectInfoForm.project"
                    :rules="projectNameRules"
                  ></v-select>
                  </v-col>
               </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" @click="showProjectDialog = false">{{$t('lang.station.cancelBtn')}}</v-btn>
            <v-btn color="primary" @click="saveProject()">{{$t('lang.station.saveBtn')}}</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- 设置解算对话框 -->
    <v-dialog v-model="showResolvingDialog" max-width="500">
      <v-card>
          <v-card-title>{{$t('lang.project.resolvingList')}}</v-card-title>
          <v-card-text>
            <v-row class="font-weight-bold mt-2">
              <v-col cols="6">{{$t('lang.station.referenceStation')}}</v-col>
              <v-col cols="6">{{$t('lang.station.operation')}}</v-col>
            </v-row>
            <v-row dense class="d-flex align-center">
              <v-col cols="6">
                <div v-for="(id,i) in resolvingRefStationIds" :key="i">{{stationList.filter(station => station.id == id)[0].name}}</div>
              </v-col>
              <v-col cols="6">
                <v-switch :label="resolvingRoverStation.running ? $t('lang.project.start') : $t('lang.project.stop')" v-model="resolvingRoverStation.running" @click="dealResolving(resolvingRoverStation, resolvingRoverStation.running)"></v-switch>
              </v-col>
            </v-row>      
          </v-card-text>
           <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" @click="showResolvingDialog = false">{{$t('lang.station.cancelBtn')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="tips.timeout"
      v-model="tips.showSnackbar"
      :color="tips.snackbarColor"
      :top="true"
    >{{ tips.snackbarText }}</v-snackbar>
  </v-container>
</template>
<script>
import axios from "axios";
import qs from "qs";
import commonCfg from "../config/common";
let apiUrl = commonCfg.commonUrl;
let vm;
export default {
  data() {
    return {
      searchByStation: "",
      stationHeaders: [
        { value: "sid", text: this.$t('lang.station.stationId') },
        { value: "name", text: this.$t('lang.station.stationName') },
        { value: "userId", text: this.$t('lang.register.usernameLabel') },
        { value: "type", text: this.$t('lang.station.stationType') },
        { value: "lat", text: this.$t('lang.station.latitude') },
        { value: "lon", text: this.$t('lang.station.longitude') },
        { value: "height", text: this.$t('lang.station.height') },
        { value: "isPublic", text: this.$t('lang.station.publicStation') },
        { value: "running", text: this.$t('lang.station.runningStatus') },
        //{ value: "status", text: this.$t('lang.station.status') },// ?
        //{ value: "connectedKey", text: this.$t('lang.station.connectionKey'), sortable: false },// ?
        //{ value: "createdTime", text: this.$t('lang.station.creationTime') },// ?
        { value: "actions", text: this.$t('lang.station.operation'), sortable: false },
      ],
      stationList: [],
      stationsTypes: [
        { value: "BASE", text: this.$t('lang.station.referenceStation') },
        { value: "ROVER", text: this.$t('lang.station.roverStation') },
      ],
      showDialog: false,
      form: {
        sid: '',
        stationName: '',
        latitude: '',
        longitude: '',
        height: '',
        selectedStationType: '',
        isPublicStation: false,
        id: -1,
        //running: false,
      },
      resetForm: {
        sid: '',
        stationName: '',
        latitude: '',
        longitude: '',
        height: '',
        selectedStationType: '',
        isPublicStation: false,
        id: -1,
        //running: false
      },
      stationIdRules: [
        v => !!v || this.$t('lang.station.stationIdRules.required'),
        v => /^[-_$a-zA-Z0-9]+$/.test(v) || this.$t('lang.station.stationIdRules.specialCharacterRestriction'),
        v => v.length <= 20 || this.$t('lang.station.stationIdRules.maximumCharacterLimit'),
      ],
      stationNameRules: [
        v => !!v || this.$t('lang.station.stationNameRules.required'),
        v => /^[\u4e00-\u9fa5-_$a-zA-Z0-9]+$/.test(v) || this.$t('lang.station.stationNameRules.specialCharacterRestriction'),
        v => v.length <= 20 || this.$t('lang.station.stationNameRules.maximumCharacterLimit'),
      ],
      latitudeRules: [
        v => !!v || this.$t('lang.station.latitudeRules.required'),
        v => /^[.0-9]+$/.test(v) || this.$t('lang.station.latitudeRules.specialCharacterRestriction'),
      ],
      longitudeRules: [
        v => !!v || this.$t('lang.station.longitudeRules.required'),
        v => /^[.0-9]+$/.test(v) || this.$t('lang.station.longitudeRules.specialCharacterRestriction'),
      ],
      heightRules: [
        v => !!v || this.$t('lang.station.heightRules.required'),
        v => /^[.0-9]+$/.test(v) || this.$t('lang.station.heightRules.specialCharacterRestriction'),
      ],
      // xRules: [
      //   v => !!v || this.$t('lang.station.xRules.required'),
      //   v => /^[.0-9]+$/.test(v) || this.$t('lang.station.xRules.specialCharacterRestriction'),
      // ],
      // yRules: [
      //   v => !!v || this.$t('lang.station.yRules.required'),
      //   v => /^[.0-9]+$/.test(v) || this.$t('lang.station.yRules.specialCharacterRestriction'),
      // ],
      // zRules: [
      //   v => !!v || this.$t('lang.station.zRules.required'),
      //   v => /^[.0-9]+$/.test(v) || this.$t('lang.station.zRules.specialCharacterRestriction'),
      // ],
      editIndex: -1,
      tips: {
        showSnackbar: false,
        snackbarColor: "error",
        snackbarText: "",
        timeout: -1,
      },
      fixStations: [],//用户自己的参考站或其他设为公共的参考站列表
      showDifferenceDialog: false,
      differenceForm: {
        fixStation: '',
        lock: false,
        stationId: ''
      },
      resetDifferenceForm: {
        fixStation: '',
        lock: false,
        stationId: ''
      },
      referenceStationRules: [
        v => !!v || this.$t('lang.project.referenceStationRules.required'),
      ],
      showProjectDialog: false,
      projectInfoForm: {
        roverStationId: '',
        project: ''
      },
      resetProjectInfoForm: {
        roverStationId: '',
        project: ''
      },
      projectList: [],
      projectNameRules: [
        v => !!v || this.$t('lang.project.projectNameRules.required'),
      ],
      showResolvingDialog: false,
      resolvingRefStationIds: [],
      resolvingRoverStation: ''
      
    };
  },
  created() {
    vm = this;
    this.getStationList()
    this.getProjectList()
  },
  methods: {
    createStation() {
      this.editIndex = -1
      this.resetForm.selectedStationType = this.stationsTypes[0]
      this.form = JSON.parse(JSON.stringify(this.resetForm))
      if(this.$refs.form) this.$refs.form.resetValidation()
    },
    editStation(item) {
      this.editIndex = this.stationList.findIndex(station => station.id == item.id)
      this.form = {
        sid: item.sid,
        stationName: item.name,
        latitude: item.lat,
        longitude: item.lon,
        height: item.height,
        //x: item.x,
        //y: item.y,
        //z: item.z,
        selectedStationType: item.type == 'BASE' ? this.stationsTypes[0] : this.stationsTypes[1],
        isPublicStation: item.isPublic,
        id: item.id,
        running: item.running
      }
    },
    getStationList() {
      axios.get(`${apiUrl}station/list`).then(res => {
        if(res.status == 200) {
          console.log(res.data)
          vm.stationList = res.data  
        }
      }).catch(error => {
        console.log(error)
      })
    },
    //通知提示工具
    showTips(text, color, timeout) {
      this.tips.showSnackbar = true;
      this.tips.snackbarText = text;
      this.tips.snackbarColor = color;
      if (timeout) {
        this.tips.timeout = timeout;
      } else {
        this.tips.timeout = 3000;
      }
    },
    //新建/编辑站
    saveStation() {
      if(!this.$refs.form.validate()) return
      this.showDialog = false
      //参数列表
      let param = {}
      param['sid'] = this.form.sid
      param['name'] = this.form.stationName  
      param['type'] = this.form.selectedStationType.value
      if(param['type'] == 'BASE') {
        param['lon'] = Number(this.form.longitude)
        param['lat'] = Number(this.form.latitude)
        param['height'] = Number(this.form.height)
        param['isPublic'] = this.form.isPublicStation
      }else {
        param['isPublic'] = false
      }
      //param['x'] = Number(this.form.x)
      //param['y'] = Number(this.form.y)
      //param['z'] = Number(this.form.z)
      //param['isPublic'] = param['type'] == 'ROVER' ? null : this.form.isPublicStation
      //param['running'] = param['type'] == 'BASE' ? null : this.form.running
      if(this.form.id != -1){// 编辑 用户可以看到所有公共的参考站 判断不是该用户的站不能编辑/删除
        param['id'] = this.form.id
      }
      console.log(param)
      axios.post(`${apiUrl}station/edit`, param).then(res => {
        if (res.status == 200) {
          this.showTips(vm.$t("lang.tips.operationSuccess"), "success");
          vm.getStationList();
          return;
        }
        this.showTips(vm.$t("lang.tips.operationFailed"), "error");
      }).catch(error => {
        console.log(error)
        this.showTips(vm.$t("lang.tips.operationFailed"), "error");
      }) 
    },
    //设置参考站
    setFixStation(item){
      console.log(item,item.projectId)
      if(item.projectId) {//移动站已经在项目里，不能再设置参考站
        this.showDifferenceDialog = false
        this.showTips(vm.$t("lang.station.noSetRefStation"), "error");
        return
      }
      this.showDifferenceDialog = true
      this.differenceForm = JSON.parse(JSON.stringify(this.resetDifferenceForm))
      if(this.$refs.differenceForm) this.$refs.differenceForm.resetValidation()

      //根据角色获得对应的参考站列表
      if(this.$root.authority == 'ROLE_USER') {//用户自己的参考站或其他设为公共的参考站
        this.fixStations = this.stationList.filter(data => data.type == 'BASE')
      }else if(this.$root.authority == 'ROLE_ADMIN' || this.$root.authority == 'ROLE_USER_ADMIN'){//管理员
        let fixStationsByUser = this.stationList.filter(data => item.userId == data.userId && data.type== 'BASE')//用户自己的参考站
        let otherPublicFixStations = this.stationList.filter(data => item.userId != data.userId && data.type== 'BASE' && data.isPublic)//其他设为公共的参考站
        this.fixStations = fixStationsByUser.concat(otherPublicFixStations)
      }
      this.differenceForm.stationId = item.id
      //若该流动站之前设置过参考站，则显示出来
      if(item.baseStationId) {
        let index = this.fixStations.findIndex(station => station.id == item.baseStationId)
        this.differenceForm.fixStation = this.fixStations[index]
      }else{
        this.differenceForm.fixStation = ''
      }
      this.differenceForm.lock = item.lockBase
    },
    //设置差分（流动站和参考站的对应关系）
    saveDifference() {
      if(!this.$refs.differenceForm.validate()) return
      this.showDifferenceDialog = false
      let param = {
        stationId: this.differenceForm.stationId,
        baseStationId: this.differenceForm.fixStation.id,
        lockBase: this.differenceForm.lock
      }
      //console.log(param)
      axios.post(`${apiUrl}station/updateBase`, qs.stringify(param)).then(res => {
        if (res.status == 200) {
          this.showTips(vm.$t("lang.tips.operationSuccess"), "success");
          vm.getStationList();
          return;
        }
        this.showTips(vm.$t("lang.tips.operationFailed"), "error");
      }).catch(error => {
        console.log(error)
        this.showTips(vm.$t("lang.tips.operationFailed"), "error");
      })
    },
    //获得项目列表
    getProjectList() {
      axios.get(`${apiUrl}project/list`).then(async(res) => {
        console.log(res.data)
        if(res.status == 200) {
          vm.projectList = res.data
        }
      }).catch(error => {
        console.log(error)
      })
    },
    setProject(item) {
      if(item.baseStationId && item.lockBase) {// 站已经有参考站且关系被锁住，不能再设置项目
        this.showProjectDialog = false
        this.showTips(vm.$t("lang.station.noSetProject"), "error");
        return
      }
      this.showProjectDialog = true
      this.projectInfoForm = JSON.parse(JSON.stringify(this.resetProjectInfoForm))
      if(this.$refs.projectInfoForm) this.$refs.projectInfoForm.resetValidation()
      if(item.projectId) { //站添加过项目，则显示出来
        this.projectInfoForm.project = this.projectList.filter(project => project.id == item.projectId)[0]
      }
      this.projectInfoForm.roverStationId = item.id
    },
    //流动站添加项目
    saveProject() {
      if(!this.$refs.projectInfoForm.validate()) return
      this.showProjectDialog = false
      let param = {
        projectId: this.projectInfoForm.project.id,//项目id
        stationId: this.projectInfoForm.roverStationId,//流动站的站id
      }
      console.log(param)
      axios.post(`${apiUrl}project/add-station`, qs.stringify(param)).then(res => {
        if (res.status == 200) {
          this.showTips(vm.$t("lang.tips.operationSuccess"), "success");
          vm.getProjectList();
          return;
        }
        this.showTips(vm.$t("lang.tips.operationFailed"), "error");
      }).catch(error => {
        console.log(error)
        this.showTips(vm.$t("lang.tips.operationFailed"), "error");
      })
    },
    //删除流动站下的项目
    delProject(item) {
      //删除确认
      this.$dialog
        .confirm({
          text: this.$t("lang.tips.userList.delTips"),
          title: this.$t("lang.tips.userList.delConfirm"),
          cancel: this.$t("lang.station.cancelBtn"),
          actions: [
            {
              text: this.$t("lang.station.cancelBtn"),
              key: "false",
            },
            {
              text: this.$t("lang.package.confirmBtn"),
              color: "blue",
              key: true,
            },
          ],
        })
        .then(async (type) => {
          if (!type) return;
          let param = { stationId: item.id }
          axios.post(`${apiUrl}project/del-station`, qs.stringify(param)).then(res => {
            if (res.status == 200) {
              this.showTips(vm.$t("lang.tips.operationSuccess"), "success");
              vm.getProjectList();
              return;
            }
            this.showTips(vm.$t("lang.tips.operationFailed"), "error");
          }).catch(error => {
            console.log(error)
            this.showTips(vm.$t("lang.tips.operationFailed"), "error");
          })  
        });
    },
    //设置解算
    setResolving(item) {
      this.resolvingRoverStation = item
      this.resolvingRefStationIds = []
      if(item.projectId) { // 当前站设置了项目，获得参考站列表
        this.resolvingRefStationIds = this.projectList.filter(project => project.id == item.projectId)[0].baseStationIds
        return
      }
      //设置了参考站关系
      this.resolvingRefStationIds.push(item.baseStationId)
    },
    //启动/停止解算任务
    dealResolving(station, flag) {
      console.log(station,flag)
      this.showResolvingDialog = false
      let operation = flag ? 'start' : 'stop'
      axios.post(`${apiUrl}station/${operation}`, qs.stringify({stationId: station.id})).then(res => {
        console.log(res)
        if (res.status == 200 && res.data == '') {
          this.showTips(vm.$t("lang.tips.operationSuccess"), "success");
          vm.getProjectList();
          return;
        }
        this.showTips(vm.$t("lang.tips.operationFailed"), "error");
        station.running = !station.running
      }).catch(error => {
        console.log(error)
        this.showTips(vm.$t("lang.tips.operationFailed"), "error");
        station.running = !station.running
      })  
    },
    //删除站
    delStation(stationId){
      //删除确认
      this.$dialog
        .confirm({
          text: this.$t("lang.tips.userList.delTips"),
          title: this.$t("lang.tips.userList.delConfirm"),
          cancel: this.$t("lang.station.cancelBtn"),
          actions: [
            {
              text: this.$t("lang.station.cancelBtn"),
              key: "false",
            },
            {
              text: this.$t("lang.package.confirmBtn"),
              color: "blue",
              key: true,
            },
          ],
        })
        .then(async (type) => {
          if (!type) return;
          let param = { id: stationId }
          axios.post(`${apiUrl}station/del`, qs.stringify(param)).then(res => {
            if (res.status == 200) {
              this.showTips(vm.$t("lang.tips.operationSuccess"), "success");
              vm.getStationList();         
              return;
            }
            this.showTips(vm.$t("lang.tips.operationFailed"), "error");
          }).catch(error => {
            console.log(error)
            this.showTips(vm.$t("lang.tips.operationFailed"), "error");
          })  
        });
    }
  }
};
</script>
